$(function () {
    $('.where_to_container').each(function () {
        const $container = $(this);

        $(".where_to_slider", $container).slick({
            dots: true,
            infinite: false,
            loop: false,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            arrows: true,
            prevArrow: $('.content .room_details .prev-btn', $container),
            nextArrow: $('.content .room_details .next-btn', $container),
        })
            .on('afterChange', function (event, slick, currentSlide) {
                $('.where_to_stay_tab .section_tab', $container)
                    .removeClass('active')
                    .eq(currentSlide).addClass('active');
                const $currentImageContainer = $(".image_container", $container).eq(currentSlide);
                if (!$currentImageContainer.hasClass('slick-initialized')) {
                    $currentImageContainer.slick({
                        dots: false,
                        infinite: true,
                        speed: 300,
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        arrows: true,
                    });
                }
                $(".image_container.active", $container).removeClass('active');
                $currentImageContainer.addClass('active');
            });

        $(".image_container.active", $container).slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            arrows: true,
        });

        $('.where_to_stay_tab .section_tab', $container).on('click', function () {
            const $t = $(this);
            $('.where_to_stay_tab .section_tab', $container).removeClass('active');
            $t.addClass('active');
            $(".where_to_slider", $container).slick('slickGoTo', $t.index());
        });
    })
});
